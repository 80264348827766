.statusNav__item {
  position: relative;
}
.statusNav__item::after {
  content: '';
  position: absolute;
  top: calc(100% - 0.5rem);
  left: calc(1.75rem - 7.5px);
  width: 2px;
  height: 1.75rem;
  background-color: #999;
  z-index: 0;
}
.currentStatus:after {
  height: 100%;
  top: 22px;
}
.statusNav__item:last-of-type::after {
  display: none;
}

.currentStatus,
.currentStatus ~ .currentStatus {
  color: #08364f;
  font-size: 1.4rem !important;
  font-weight: 600;
  min-height: 55px;
  align-items: flex-start !important;
  padding-bottom: 20px !important;
  padding-left: 8px;
  /*rgb(4, 65, 97);rgb(8, 54, 79)*/
}
.currentStatus .statusText,
.currentStatus ~ .currentStatus .statusText {
  position: relative;
  top: 0;
}

/* .currentStatus ~ h3 {
  color: rgb(228, 228, 231);
} */
.currentStatus ~ h3 {
  color: #999;
  padding-left: 10px;
  padding-bottom: 0px;
  font-weight: 600;
  margin-top: 0px !important;
}
.currentStatus ~ h3 svg {
  height: 17px;
  width: 17px;
  border-radius: 50%;
  background-color: #999;
  position: relative;
  border-color: #999;
  z-index: 2;
  border-width: 0px !important;
  left: 2.5px;
}
.currentStatus ~ h3 .statusText span {
  position: relative;
  left: 0;
}
h3.Sidebar_currentStatus .status-icon:after {
  display: none;
}
/* .currentStatus ~ .between .status-icon:after{
  opacity: 1;
} */
/* .currentStatus ~ h3 svg:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  height: 15px;
  width: 15px;
  z-index: 4;
  border-radius: 50%;
} */
.currentStatus {
  color: #08364f;
  font-size: 1.25rem;
  font-weight: 600;
  min-height: 70px;
}
.currentStatus svg,
.currentStatus ~ .currentStatus svg {
  color: #fff;
  background-color: #08364f;
  border-color: #08364f;
  border-radius: 4px;
  height: 1.45rem;
  width: 1.45rem;
  padding: 0;
  position: relative;
  z-index: 1;
  left: 1px;
}
.status-icon svg {
  border-width: 0px !important;
}
.currentStatus .statusText,
.currentStatus ~ .currentStatus .statusText {
  position: relative !important;
  top: 0px !important;
  left: -2.8px;
}
.currentStatus .statusText {
  position: relative !important;
  top: 0 !important;
}
/* .currentStatus ~ h3::before {
  content: '';
  display: none;
} */
/* .between::after {
  background-color: #08364f;
} */
.between::after {
  background-color: #999;
}
.testconfirm {
  color: red;
}
/* .between, .currentStatus.between {
  min-height: auto;
  height: 1.75rem;
  top: calc(100% - 0.5rem);
} */
/* .between svg, .currentStatus.between svg{
background-color: #999;
border-color: #999;
} */
/* .currentStatus ~ .between::after,
.between::after {
  background-color: rgb(4, 65, 97);
} */
/* .status-icon{
  position:relative;
}
.currentStatus .status-icon::after {
    content: '';
    position: absolute;
    left: 7px;
    top: 7px;
    height: 13px;
    width: 13px;
    background: #fff;
    border-radius: 50%;
    opacity: 1;
} */

.currentStatus ~ .between::after {
  background-color: #999;
  height: 2.75rem;
}
.currentStatus ~ h3 {
  position: relative;
}
.currentStatus ~ h3::before {
  content: '';
  position: absolute;
  left: 17.5px;
  top: 13px;
  height: 7px;
  width: 7px;
  background-color: #fff !important;
  border-radius: 50%;
  opacity: 1;
  z-index: 3;
}
.currentStatus ~ .currentStatus::before {
  display: none;
}
.currentStatus ~ .currentStatus .statusText {
  position: relative;
}

/* new css */
/* .currentStatus ~ .currentStatus.between svg, .currentStatus svg{
  color: #fff;
  border-color: #999;
  background-color: #999;
} 
 .currentStatus ~ .currentStatus.between, .currentStatus{
  color: #999;
  min-height: auto;
} */
.currentStatus.between svg {
  color: #fff;
  border-color: #999;
  background-color: #999;
}

.currentStatus.between {
  color: #999;
  min-height: auto;
  font-size: 1rem !important;
  /* align-items: center !important; */
  padding-bottom: 7px !important;
}

.currentStatus.between .statusText {
  position: relative !important;
  top: 3px !important;
}

.removecurrent {
  font-size: 18px !important;
  padding-left: 10px !important;
  height: auto !important;
  color: #999 !important;
}
.removecurrent svg {
  height: 20px !important;
  width: 20px !important;
  border-radius: 8px !important;
  background-color: #999 !important;
  position: relative !important;
  border-color: #999 !important;
  z-index: 2 !important;
}
.removecurrent:before {
  content: '';
  position: absolute;
  left: 16px;
  top: 15px;
  height: 8px;
  width: 8px;
  background-color: #fff !important;
  border-radius: 50%;
  opacity: 1;
  z-index: 3;
}
.removecurrent svg path {
  display: none;
}
.removecurrent .order-status-text {
  display: none !important;
}
.currentStatus ~ .currentStatus.testcolor.between {
  color: #08364f !important;
  align-items: flex-start !important;
  font-size: 1.4rem !important;
}
.testcolor:after {
  top: 25px;
}
.currentStatus
  ~ .currentStatus.testcolor.between
  .statusText
  span {
  position: relative;
  top: -3px;
}
.testcolor svg {
  background-color: #08364f !important;
  border-color: #08364f !important;
}
.testcolor {
  color: #08364f;
  margin-top: 20px !important;
}
.confirmcolor,
.testconfirm {
  align-items: flex-start !important;
  color: #08364f;
  font-size: 1.4rem !important;
  padding-bottom: 20px !important;
}
.confirmcolor .statusText span {
  position: relative;
    left: 0px;
    top: -1px;
}
.confirmcolor svg,
.testconfirm svg {
  background-color: #08364f;
  border-color: #08364f !important;
}
.confirmcolor .statusText,
.testconfirm .statusText {
  position: relative !important;
  top: 0px !important;
}
.currentStatus.trackorder ~ .currentStatus.trackorder.between,
.currentStatus.trackorder {
  align-items: flex-start !important;
}
.currentStatus.trackorder:after {
  height: 100%;
  top: 30px;
}

@media (max-width: 767px) {
  .currentStatus svg,
  .currentStatus ~ .currentStatus svg {
    height: 1.2rem;
    width: 1.2rem;
  }
  .currentStatus ~ .currentStatus.between .statusText,
  .currentStatus .statusText {
    position: relative;
    top: 1px;
  }
  .statusNav__item::after {
    left: calc(1.75rem - 14px);
  }
  .currentStatus ~ .currentStatus {
    padding-left: 3px;
  }
  .currentStatus ~ h3 svg {
    height: 17px;
    width: 17px;
    border-radius: 6px;
    position: relative;
    left: 3px;
  }
  .currentStatus.trackorder:after {
    top: 20%;
  }
  .currentStatus ~ h3::before {
    left: 12px;
    top: 12px;
    height: 7px;
    width: 7px;
  }
  .currentStatus svg,
  .currentStatus ~ .currentStatus svg {
    position: relative;
    left: 2px;
  }
  .confirmcolor {
    font-size: 16px !important;
  }
  .currentStatus,
  .currentStatus ~ .currentStatus {
    font-size: 16px !important;
  }
  .currentStatus ~ h3 svg {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    position: relative;
    left: 5px;
  }
  .currentStatus ~ .currentStatus.testcolor.between {
    font-size: 16.5px !important;
  }
  .currentStatus ~ h3 .statusText span {
    position: relative;
    left: 2.5px;
  }
  .currentStatus ~ .currentStatus.between .statusText,
  .currentStatus .statusText {
    position: relative !important;
    top: 0px !important;
    left: 0px;
  }
  .currentStatus ~ .currentStatus.between .statusText {
    left: -2px;
  }
  .currentStatus
    ~ .currentStatus.testcolor.between
    .statusText
    span {
    position: relative;
    top: 1px;
  }
  .confirmcolor .statusText span {
 
    left: 0px;
    top: 0px;
}
}
