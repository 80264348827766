@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');
html,
body {
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}
/* new structure css */
.bold {
  font-weight: 600;
}
.f-24 {
  font-size: 24px;
}
.f-22 {
  font-size: 21px;
}
.f-20 {
  font-size: 18px;
}
.text-blue {
  color: #08364f !important;
}
.top-header-box {
  padding: 20px 0;
}
.customBgColor {
  background-image: linear-gradient(180deg, #adfff7, #d5fdfd);
  padding-top: 2%;
}
.header_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
}
.brand-logo img {
  height: 45px;
}
.logout_btn {
  display: inline;
  color: #08364f;
  font-weight: 500;
  font-size: 20px;
}
.logout_btn:hover {
  text-decoration: underline !important;
}
.logout_btn a:hover {
  text-decoration: none;
  color: #08364f;
}
.logout_btn img {
  height: 15px;
}
.logout_btn .semi-bold {
  margin-left: 10px;
}
.brand_logo img {
  height: 50px;
}
.brand-logo {
  display: flex;
  align-items: center;
}
.main-heading {
  font-size: 2.5rem;
  line-height: 0.9;
  font-weight: 600;
}
.order-header {
  padding: 30px 0px;
  padding-bottom: 40px;
}
.order-header-icon {
  width: 27px;
  height: 27px;
  margin-right: 16px;
  position: relative;
  bottom: 4px;
}
.big-icon {
  width: 32px;
  height: 32px;
}
.ship-date-range {
  display: flex;
  flex-direction: row;
}
.order-date-box {
  display: grid;
  width: 100%;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: row;
  right: 30px;
  position: relative;
  top: 8px;

  grid-gap: 4px;
  grid-template-columns: 31.33% 3% 31.33% 3% 31.33%
}
.order-date-arrow svg {
  height: 1.1rem;
}
.row.align-item {
  align-items: flex-end;
}

.seperator {
  width: 10px;
  margin-left: 4px;
  margin-right: 4px;
  position: relative;
  bottom: 3px;
}
.ship-date, .delivery-date {
  min-width: auto;
  display: flex;
  flex-direction: row;
  position: relative;
  margin-left: 4%;
}
.ship-date-inner, .delivery-date-inner {
  display: flex;
  flex-direction: column;
}
.order-date-box p.text-blue {
  font-size: 13px;
  padding-bottom: 6px;
  line-height: 0;
  font-weight: 500;
}
.ship-date {
  grid-row: 1;
  align-items: center;
  justify-content: center;
}
.delivery-date {
  grid-row: 1;
  justify-content: center;
  align-items: center;
}
.order-date {
  display: flex;
  flex-direction: row;
  grid-row: 1;
  align-items: center;
  justify-content: center;
}
.order-date-arrow {
  grid-row: 1;
}
/* .order-date:after {
  content: '';
  height: 20px;
  width: 20px;
  right: -5px;
  top: 15px;
  position: absolute;
  background-image: url('../public/chevron-left.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
} */
/* side bar css */
.sidebar {
  border-radius: 5px;
  padding: 20px;
  position: relative;
}
.order-status-hd {
  border-bottom: 2px solid #08364f;
  padding: 13px;
  display: flex;
  align-items: center;
  font-weight: 600;
  color: #08364f;
  font-size: 1.125rem;
  padding-top: 0px;
}
span.cal-logo {
  display: flex;
  margin-right: 15px;
}
.cal-logo img {
  height: 27px;
}
.mrt-0 {
  margin-top: 0px !important;
}
/* .sidebar-nav h3 .order-status-text {
  display: none;
  position: absolute;
} */
.sidebar-nav h3 .order-status-text {
  display: block;
  font-size: 12px;
  color: #999;
  font-weight: 400;
  /* padding-top: 5px; */
}
.newclass,
.tacking-link {
  display: block;
  font-size: 12px;
  color: #999;
  font-weight: 400;
  /* position:absolute;
  top:22px; */
}
.sidebar-nav h3 {
  position: relative;
  align-items: flex-start;
}
/* main body css */
.tab-icon {
  display: flex;
  width: 45px;
  text-align: left;
}
.accordian-box .card-header .btn-link {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 !important;
  color: #08364f;
  text-decoration: none;
  font-size: 1.4rem;
  font-weight: 600;
}
.accordian-box .card-header .btn-link:focus {
  box-shadow: none;
}
.accordian-box .card {
  margin-bottom: 30px;
  border: none;
  background: #fff !important;
}
.accordian-box .card-header {
  border-bottom: none;
  padding: 1.35rem 2rem;
  background: #fff !important;
  border-radius: 5px;
  position: relative;
}
div#acc-sidebar .card-header {
  padding: 1.35rem 1.8rem !important;
}
.banner-text {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #e91f27;
  color: #fff;
  font-weight: 300;
  font-size: 12px;
  min-width: 48.5%;
  text-align: center;
  border-radius: 0px 5px;
}
.yellow-banner {
  background-color: #e9961f;
}
.sidebar-banner {
  width: 96%;
  left: 2%;
  border-radius: 0px 0px 4px 4px;
}
.accordian-box .card-body {
  padding-top: 0px;
}
.card-body-inner {
  border-top: 2px solid #08364f;
}
.item-box {
  padding: 10px;
}
.text-brand {
  color: #08364f;
}

.brand-btn {
  background-color: #08364f !important;
  color: #fff;
  padding: 0.4rem 0.4rem;
  text-transform: uppercase;
  letter-spacing: 0px;
  font-size: 16px;
  font-weight: 500;
  outline: none;
  box-shadow: none !important;
  border-radius: 4px;
  position: relative;
  min-width: 140px;
  max-width: 180px;
  width: 100%;
  text-transform: capitalize;
  border: 1px solid #08364f;
}
.popup-btn {
  max-width: 140px;
}
.brand-btn:hover {
  text-decoration: none;
  color: #fff;
}
.item-outer {
  border: 2px solid #d7d7d7;
  margin-top: 20px;
  padding: 0px 15px;
  border-radius: 5px;
  position: relative;
  margin-bottom: 25px;
}
button:hover {
  background-color: #08364f;
}
.btn-width {
  max-width: 140px;
  /* padding: 0.75rem 0px !important; */
  justify-content: center !important;
  width: 100%;
  margin: 10px;
  position: relative;
}
.position-text {
  position: absolute;
}
.popup-dialog .modal-content {
  border: none;
}
.cursor-pointer {
  cursor: pointer;
}
.sm-btn {
  border: 1px solid #08364f;
  padding: 2px 10px;
  border-radius: 4px;
  color: #08364f;
}
.border-box.item-outer {
  padding: 10px 15px !important;
}
.qty-box .qty-input {
  min-width: 70px;
  justify-content: center;
  color: #08364f !important;
  font-weight: 800;
  text-align: center;
  border-radius: 0px;
}
.qty-box {
  color: #08364f;
}
.qty-box button,
.qty-box button:focus,
.qty-box button:hover {
  background-color: #d7d7d7;
  font-weight: 800;
  color: #08364f !important;
  outline: none !important;
  box-shadow: none !important;
}
.footer-btn {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 25px;
  padding-left: 10px;
  padding-right: 10px;
}

.contact-btn {
  color: #fff;
  min-width: 140px;
  padding: 0.45rem 0.45rem;
  border-radius: 5px;
  outline: none !important;
  font-size: 15px;
  font-weight: 500;
}
.contact-btn a:hover {
  text-decoration: none;
}
.bg-red,
.bg-red:hover {
  background-color: #e91f27 !important;
  color: #fff !important;
}
.bg-brand,
.bg-brand:hover,
.bg-brand a:hover {
  background-color: #08364f !important;
  color: #fff !important;
}
.optional-text {
  min-height: 180px;
  padding: 15px;
  margin-top: 55px;
  font-size: 14px;
}
.top-header-item .heading {
  color: #08364f;
  text-decoration: none;
  font-size: 1.25rem;
  font-weight: 600;
}
.top-header-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #08364f;
  padding: 12px 10px;
  margin: 5px;
  margin-bottom: 2px;
  padding-bottom: 20px;
}
.edit-item-popup .modal-content {
  padding: 0px 12px;
  padding-bottom: 12px;
}
.edit-item-popup .img-crop-box {
  height: 220px !important;
  min-width: 100%;
  width: 100%;
  border-width: 3px;
}
.img-crop-box {
  background-color: #fbfcff !important;
}
.edit-item-popup .img-crop-outer {
  min-width: 360px;
}
.select-box {
  position: relative;
}
.select-icon {
  color: #08364f;
  position: absolute;
  right: 15px;
  top: 5px;
  font-size: 22px;
}
.select-box select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: transparent;
  position: relative;
  z-index: 1;
  outline: none;
  border-radius: 4px !important;
  padding-right: 30px;
  border-width: 2px;
}
input,
optgroup,
select,
textarea {
  border-width: 2px !important;
  border-color: rgba(0, 0, 0, 0.125) !important;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.select-icon {
  color: #08364f;
  position: absolute;
  right: 15px;
  top: 5px;
  font-size: 22px;
}
.select-box select {
  color: #08364f;
}
.text-gray {
  color: #999;
}
.semi-bold {
  font-weight: 500;
}
#collapseTwo .item-qty,
#collapseFour .item-qty,
#collapseFive .item-price {
  opacity: 0;
}
.mob-show {
  display: none;
}
.d-flex-item {
  display: flex;
}
.edit-items-box-inner {
  align-items: center;
  margin-top: 0px !important;
  padding-top: 1rem !important;
}
.item-outer .banner-text {
  right: -7px;
}
/* card enable/disable */
.card.disabled {
  pointer-events: none;
}

.disable-icon {
  display: none !important;
}
.card.disabled .disable-icon {
  display: flex !important;
}
.card.disabled .enable-icon {
  display: none;
}
.enable-icon,
.disable-icon {
  display: flex;
}
.card.disabled .btn-link {
  color: #999;
}
.card.disabled .banner-text {
  background-color: #999;
}
.colorGray {
  /* background-color: rgba(0, 0, 0, 0.125); */
  background-color: #d8d8d8;
}
.colorGrayBorder {
  border: 2px solid #d8d8d8;
  border-radius: 0px 2px;
}
.colorYellow {
  background-color: #e9961f !important;
}
.colorGreen {
  background-color: #4bb543 !important;
}
button.contact-btn.bg-gray {
  background-color: #999;
  pointer-events: none;
}
.sidebar-bottom-link ul {
  text-align: center;
}

.sidebar-bottom-link ul a,
.sidebar-bottom-link ul button {
  font-size: 12px;
  text-decoration: underline;
}
.sidebar-bottom-link ul a:hover {
  color: #08364f;
}
.sidebar-bottom-link ul li {
  display: inline-block;
  padding: 0px 20px;
  position: relative;
}

.sidebar-bottom-link ul li:after {
  content: '';
  height: 4px;
  width: 4px;
  background: #10364f;
  left: 0;
  top: 11px;
  position: absolute;
  border-radius: 50%;
}
.sidebar-bottom-link ul li:first-child:after {
  display: none;
}
#sidebar-heading {
  pointer-events: none;
}
.logout_btn button:hover,
.logout_btn button,
.logout_btn button:focus {
  background-color: transparent !important;
  outline: none !important;
  box-shadow: none !important;
}
.logout_btn button:hover {
  text-decoration: underline;
}
.font-w-regular dt {
  font-weight: 400;
}
.address-title {
  font-size: 16px;
  font-weight: 600;
  color: #08364f;
}
/* icons css */
.accordian-box .card-header .btn-link svg,
.cart-icon svg {
  height: 1.6rem;
  color: #08364f;
}
.card.disabled svg {
  color: #999 !important;
}
.modal-dialog {
  max-width: 500px;
  margin: auto auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  width: 100%;
}
.address-box-inner {
  margin: 0;
}
.address-box-inner p {
  margin: 0;
}
.address-box-outer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}
.border-out,
.border-out:hover {
  background-color: #fff !important;
  border: 1px solid #08364f;
  color: #08364f;
  text-decoration: none;
  margin-top: 10px;
}
.gap-btween {
  gap: 2rem !important;
  padding: 0.5rem 0px !important;
}
.text-size {
  font-size: 1rem;
  line-height: 1.5rem;
}
.address-form label {
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 0px;
  text-transform: capitalize;
}
.crop-photo-box .img-crop-box {
  min-height: 14rem;
  border-width: 3px;
}
a.tacking-link {
  position: relative;
  top: -12px;
  font-size: 12px;
  font-weight: 500;
}
.address-form input {
  color: #08364f;
  font-size: 1rem;
}
.colorBlue {
  background-color: #08364f;
}
.border-gray {
  border: 2px solid #d8d8d8;
}
.border-blue {
  border: 2px solid #08364f;
}
.border-light-gray {
  border: 2px solid #d7d7d7;
}
.border-red {
  border: 2px solid #e91f27;
}
.repalcement-item {
  margin-top: 20px !important;
  margin-left: 0px !important;
  padding: 12.5px !important;
  margin-right: 0px !important;
  align-items: center;
}
.refund-qty {
  place-self: center !important;
}
.qty-box button {
  border: 2px solid #d7d7d7;
}
.repalcement-item .banner-text {
  right: 0px;
}
.gaf-box {
  margin-top: 0px !important;
  padding-top: 0px !important;
}
.order-summary-item .d-flex-item img {
  max-height: 100%;
  max-width: 100%;
  height: -webkit-fill-available;
}
.order-summary-item {
  padding: 7px !important;
  padding-left: 10px !important;
}
.item-box.order-item-box {
  padding: 0px;
}
.order-summary-item {
  padding: 13px !important;
  padding-left: 12px !important;
  padding-right: 0px !important;
  padding-bottom: 3px !important;
}
.address-box-outer {
  padding-left: 2px;
}
.edit-items-box-inner {
  padding: 5px !important;
  padding-top: 13px !important;
  padding-bottom: 12px !important;
}
#collapseTwo .card-body-inner .item-outer:last-child {
  margin-bottom: 0px !important;
}
.border-box.item-outer {
  padding: 11px 12px !important;
  padding-bottom: 4px !important;
}
.edit-items-box-inner .d-flex-item img,
.reorder-flex-mob .d-flex-item img {
  /* height: 100%; */
  max-width: 100%;
  max-height: 100%;
  height: -webkit-fill-available;
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #08364f;
  width: 75px;
  height: 75px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
  position: absolute;
  top: 30%;
  z-index: 9;
}
.loader-box {
  position: fixed;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 9;
  top: 0;
}
.pd-top {
  display: block;
  padding-bottom: 5px;
  /* padding-top: 3px; */
}
.confirmcolor .pd-top {
  padding-top: 0px !important;
}
.order-summary-item.repalcement-item .d-flex-item {
  width: 60%;
}
.order-summary-item.repalcement-item
  dl.font-w-regular
  .flex-item {
  align-items: flex-start;
}
#collapseThree .card-body-inner .item-box {
  padding-right: 0px;
}
.popup-btn {
  max-width: 140px;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
#collapseOne .border-top {
  border-top: 2px solid #dee2e6 !important;
}
.mob-pl {
  padding-right: 0px;
}
.item-outer.repalcement-item .img-size img {
  height: 100%;
}
.repalcement-item .banner-text {
  min-width: 47.5%;
}
/* new css */
.modal-body {
  padding-left: 0.3rem;
  padding-right: 0.3rem;
}
.modal-content-box .img-crop-outer h3 {
  /* padding-left: 10px; */
}
.crop-box {
  column-gap: 2rem;
  display: flex;
  flex-direction: column-reverse;
}
.address-box {
  padding-top: 19px;
}
.item-qty {
  text-transform: uppercase;
}
#collapseOne .order-summary-item.repalcement-item:last-child {
  padding-bottom: 17px !important;
}
#collapseFive .card-body-inner .item-box {
  padding-bottom: 0px;
}
#collapseFour .item-box {
  padding-top: 5px;
}
h3.order-heading {
  line-height: 1;
}

.desktop-hide {
  display: none !important;
}
.repalcement-item .img-size {
  margin-right: 15.5px;
  height: 200px;
}
.img-size {
  max-height: 108px;
  overflow: hidden;
  max-width: 135px;
  height: 200px;
}
.img-size img {
  object-fit: cover;
  object-position: top;
  /* width: 100%; */
  border-width: 0px;
}
/* media query */
@media (min-width: 2100px){
.item-outer .banner-text {
    right: -7px;
    top: -1px;
}
}
@media (min-width: 1710px) and (max-width: 2050px){
.item-outer .banner-text {
    right: -9px;
    top: -2px;
}
}
@media (min-width: 1410px) and (max-width: 1550px){
.item-outer .banner-text {
    right: -8px;
    top: -1px;
}
}
@media (min-width: 1400px) {
  
 .card-body-inner{
    border-top: 3px solid #08364f;
}
.top-header-item {
    border-bottom: 3px solid #08364f;
}
.item-outer{
  border-width: 2px;
}
input, optgroup, select, textarea {
    border-width: 2px !important;
    border-color: rgba(0, 0, 0, 0.125) !important;
}
#collapseOne .border-top {
    border-top: 3px solid #dee2e6 !important;
}
/* .qty-box button {
    border: 3px solid #d7d7d7;
} */
}
@media (min-width: 1350px) {
  .container {
    max-width: 1200px;
  }
}
@media (min-width: 1100px) and (max-width: 1250px) {
  .repalcement-item {
    padding: 12px !important;
  }
  .edit-items-box-inner {
    padding-top: 12px !important;
  }
  .edit-items-box-inner .img-size {
    margin-right: 15.5px;
  }
}
@media (min-width: 1000px) and (max-width: 1100px) {
  .contact-btn,
  .btn-width {
    min-width: 110px;
    font-size: 12px;
  }
  .item-outer .banner-text {
    top: -1px !important;
}
.banner-text{
  font-size: 10px;
}
}

@media (min-width: 992px) and (max-width: 1200px) {
  p.tack-text {
    max-width: 160px;
  }

  .contact-btn {
    min-width: 100px;
  }

  .order-date-box {
    right: 15px;
  }

  .order-date {
    margin-right: 2.5%;
  }
  .ship-date {
    margin-left: 2.5%;
    margin-right: 2.5%;
  }
  .delivery-date {
    margin-left: 2.5%;
  }
  .order-header-icon {
    margin-right: 12px;
  }
  .order-header .f-22 {
    font-size: 18px;
  }
}

@media (max-width: 992px) {
  .order-date {
    margin-right: 2.5%;
  }
  .order-date-box {
    right: 0px;
  }
  .ship-date {
    margin-left: 2.5%;
    margin-right: 2.5%;
  }
  .delivery-date {
    margin-left: 2.5%;
  }
  .order-header-icon {
    margin-right: 12px;
  }
  .order-header .f-22 {
    font-size: 18px;
  }
  .mob-hide {
    display: none;
  }
  .mob-show {
    display: block;
    width: 50%;
    text-align: right;
  }
  .mob-show.wd-100{
    width: 100%;
  }
  .order-date-box {
    justify-content: center;
  }
  .contact-btn {
    min-width: 220px;
    padding: 14px;
  }
  .mob-flex {
    display: flex;
    flex-direction: column-reverse;
  }
  .customBgColor {
    min-height: 100vh;
  }
  .item-box.order-item-box {
    padding: 0px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .footer-btn.mob-show.wd-100 {
    justify-content: space-between;
    display: flex !important;
    margin-bottom: 20px;
    margin-top: 0px;
    width: 100%;
  }
  .footer-btn {
    padding-left: 0px;
    padding-right: 0px;
  }
  .contact-btn {
    min-width: 47% !important;
    font-size: 14px !important;
    height: 65px;
  }
  #sidebar-heading {
    pointer-events: auto;
  }
}
@media (max-width: 767px) {
  .order-date {
    margin-right: 2.5%;
    position: relative;
    bottom: 0px;
  }
  .ship-date {
    margin-left: 2.5%;
    margin-right: 2.5%;
    display: flex;
    flex-direction: column;
  }
  .delivery-date {
    margin-left: 2.5%;
  }
  .order-header-icon {
    margin-right: 0px;
    margin-bottom: 2px;
  }
  .order-date .f-22 {
    height: 18px;
    margin-top: 2px;
  }
  .order-date-box {
    display: grid;
    width: 100%;
    flex-wrap: wrap;
    align-items: flex-start;
    flex-direction: row;
    height: 78px;
    grid-gap: 0px;
    position: static;
    top: 0;
    right: 0 !important;
    left: 0;
    grid-template-columns: 32% 3% 32% 3% 32%
  }
  .order-date-arrow {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 14px;
    position: relative;
    bottom: 10px;
  }
  .order-date .order-header-icon {
    margin-bottom: 11px;
  }
  .delivery-date .order-header-icon {
    margin-bottom: 8px;
  }
  .order-header .f-22 {
    font-size: 13px;
  }
  .order-subtitle {
    min-height: 10px;
  }
  .order-date,
  .ship-date,
  .delivery-date
  {
    min-width: auto;
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 68px;
  }
  .order-subtitle {
    text-align: center;
  }
  .contact-btn {
    min-width: 180px;
    padding: 9px;
  }
  .item-qty {
    opacity: 0;
    display: none!important;
  }
  #collapseOne .d-flex-item {
    width: 82%;
  }
  .edit-items-box {
    flex-direction: column;
  }
  .edit-btn {
    text-align: center;
    margin-bottom: 5px;
    margin-top: 10px;
  }
  .qty-box {
    width: 100%;
    text-align: center;
  }
  .reorder-flex-mob {
    flex-direction: column;
  }
  .qty-box-reorder {
    position: static !important;
  }
  #collapseFour .select-box {
    margin-bottom: 15px;
  }
  .tab-text {
    font-size: 16.5px;
    line-height: normal;
  }
  .header_box {
    align-items: center;
    gap: 0;
    grid-gap: 0;
  }
  .main-heading {
    font-size: 1.6rem;
    line-height: 1;
  }
  .brand-logo img {
    height: 50px;
  }
  .sidebar-bottom-link ul li {
    padding: 0px 15px;
  }
  .sidebar-bottom-link ul li:after {
    top: 12px;
  }
  .order-date-box {
    justify-content: space-between;
    align-items: flex-end;
  }
  .order-date:after {
    right: -15px;
  }
  .banner-text {
    /* min-width: 92%; */
    width: 92%;
    left: 4%;
    border-radius: 0px 0px 5px 5px;
    right: 0;
    font-size: 10px;
  }
  .accordian-box .card-header .btn-link svg,
  .cart-icon svg {
    height: 1.3rem;
  }
  .tab-icon {
    width: 35px;
  }
  span.cal-logo {
    margin-right: 18px;
  }
  .edit-items-box img,
  .item-box img {
    /* height: 100%; */
  }
  .f-22 {
    font-size: 15px;
  }
  .f-20 {
    font-size: 13px;
  }
  .order-date-box p.text-blue {
    font-size: 13px;
  }
  .header_box {
    padding: 0px 13px;
  }
  .order-header {
    padding-bottom: 5px;
  }
  div#acc-sidebar .card-header,
  .accordian-box .card-header {
    padding: 1.2rem 1.5rem !important;
  }
  .font-w-regular .flex-item {
    align-items: flex-start;
  }
  div.item-scent dd {
    padding-left: 35px;
  }
  .img-size {
    margin-bottom: 0px;
    margin-right: 15px !important;
    margin-bottom: 0px !important;
  }
  .edit-items-box-inner {
    padding-top: 20px !important;
    padding-bottom: 3px !important;
  }
  .edit-items-box-inner .banner-text {
    /* right: -4px; */
    left: 7px;
    max-width: 96%;
    border-radius: 0px 0px 4px 4px;
  }
  .edit-items-box-inner .banner-text.colorGray.colorGrayBorder {
    top: -2px;
  }
  .order-summary-item {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .address-box-outer {
    padding-left: 2px;
    margin-left: -5px;
    margin-right: -5px;
  }
  #collapseFour .card-body,
  #collapseOne .card-body,
  #collapseTwo .card-body,
  #collapseThree .card-body,
  #collapseFive .card-body {
    margin-left: -5px;
    margin-right: -5px;
  }
  #collapseOne .border-top {
    border-top: 2px solid #dee2e6 !important;
  }
  #collapseOne .border-top .mrt-0 p,
  .address-box-outer,
  .address-title,
  dl.font-w-regular,
  .address-form label,
  .address-form input {
    font-size: 0.75rem !important;
  }
  .order-heading {
    font-size: 0.875rem !important;
  }
  .address-form label {
    padding-left: 12px;
  }
  .address-form .mob-gap {
    gap: 0.5rem !important;
  }
  .mob-pl {
    padding-right: 0px;
    padding: 20px 8px !important;
    padding-bottom: 10px !important;
  }
  .sidebar-nav h3 {
    padding-left: 3px;
    font-size: 13px;
  }
  .sidebar-nav h3 .order-status-text {
    font-size: 10px;
  }
  .sidebar-nav h3 .pd-top {
    padding-bottom: 0px;
  }
  .newclass,
  .sidebar-nav h3 .order-status-text {
    padding-top: 5px;
  }
  .accordian-box .card {
    margin-bottom: 20px;
  }
  .edit-items-box.edit-items-box-inner .d-flex-item {
    width: 100%;
  }
  .item-price {
    font-size: 0.875rem !important;
  }
  .wd-100 {
    width: 100%;
  }
  #collapseOne .order-summary-item.repalcement-item:last-child {
    padding-bottom: 15px !important;
  }
  #collapseThree .card-body-inner .item-box {
    padding-left: 0px;
  }
  .img-size {
    width: 90px;
    max-height: 120px;
  }
   .refund-qty {
    display: block;
    position: static !important;
    margin-bottom: 10px;
  }
  .optional-text {
    min-height: 10px;
    margin-top: 10px;
    padding: 0px;
  }
}
@media (max-width: 512px) {
  .order-date-arrow svg {
    height: 1.1rem;
  }
  .row.align-item {
    align-items: flex-end;
  }

  .ship-date, .delivery-date {
    margin-left: 8px;
  }
  .order-date-box p.text-blue {
    font-size: 13px !important;
  }
  .ship-date {
    margin-left: 5px;
    margin-right: 5px;
  }
  .order-date {
    margin-right: 5px;
    position: relative;
    top: 1px;
  }
  .order-header .f-22 {
    font-size: 13px;
  }
  .seperator {
    width: 5px;
    margin-left: 4px;
    margin-right: 4px;
    position: relative;
    bottom: 0px;
    right: 1px;
  }
  .contact-btn {
    min-width: 120px;
    padding: 7px;
    font-size: 12px;
  }
  .f-24 {
    font-size: 15px;
  }
  .ship-date-range {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .order-date,
  .ship-date {
    min-width: auto;
  }
  .ship-date {
    margin-left: 4px;
  }
  .order-date {
    margin-right: 4px;
  }
  .delivery-date {
    margin-left: 4px;
  }
  .ship-date p,
  .order-date p {
    font-size: 10px;
  }
  .order-date:after {
    content: '';
    height: 14px;
    width: 14px;
  }
  .btn-width {
    min-width: 125px;
    margin: 7px;
  }
 
  #collapseFour .d-flex-item h3,
  #collapseOne .d-flex-item h3 {
    font-size: 0.875rem;
  }
  #collapseFour .d-flex-item dl,
  #collapseOne .d-flex-item dl {
    font-size: 0.75rem;
  }
  .edit-item-popup .img-crop-outer {
    min-width: 100%;
    margin-top: 1.5rem;
  }
  /* .brand-logo {
    width: 60%;
  } */
  .brand-logo img {
    height: 33px;
  }
  .brand-logo {
    width: 50%;
  }
  .order-date-arrow svg {
    height: 0.875rem;
    padding-bottom: 1px;
  }
  .main-heading {
    font-size: 18px;
    line-height: 1;
  }
}
@media (min-width: 576px) {
  .edit-item-popup {
    max-width: 800px;
  }
  .cancel-order-popup {
    max-width: 600px;
  }
  .order-date {
    margin-right: 8px;
    position: relative;
    bottom: 0px;
  }
}
@media (max-width: 413px) {
  .order-date-box p.text-blue {
    font-size: 12px !important;
  }
}
@media (max-width: 385px) {
  .order-date-box {
    height: 78px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .order-date-box p.text-blue {
    font-size: 11px !important;
  }

  .order-subtitle {
    font-size: 12px !important;
  }
}
@media (max-width: 375px) {
  .order-date-box {
    height: 78px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .order-subtitle {
    font-size: 10px !important;
  }

  .order-date-box .f-22 {
    font-size: 11px !important;
  }
}

/* Toastr Style  */
#toast-container {
  margin-top: 1rem;
}

#toast-container.toast-top-full-width>.toast {
  max-width: 570px;
}

#toast-container>.toast-success {
  background-color: #76cd65;
  /* background-color: #61bd4f; */
  /* background-color: aliceblue; */
  opacity:1;
  box-shadow:0px 0px 10px rgba(0,0,0,0.2);
}

#toast-container>.toast-success:hover {
  background-color: #71cc5f;
  /* background-color: aliceblue; */
  opacity:1;
  box-shadow:0px 0px 10px rgba(0,0,0,0.4);
}

#toast-container>.toast-success {
  background-image: url('../public/popup-small.png')!important;
  background-size: 24px auto;
  /* color: #08364f!important; */
  color: #ffffff!important;
}